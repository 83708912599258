/**
 * 用于打包聚合版，该文件不会存在于构建后的目录 
 */
 
qfui.define(function(exports){
  var cache = qfui.cache;
  qfui.config({
    dir: cache.dir.replace(/lay\/dest\/$/, '')
  });
  exports('qfui.all', qfui.v);
});